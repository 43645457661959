import React from 'react'
import ErrorImage from '../assets/images/img-error-500.svg'

export default class ErrorBoundary extends React.Component {

    state = {
        error: false
    }

    componentDidCatch(error) {
        this.setState({ error })
    }

    render() {

        if (!this.state.error) return this.props.children
        else {
            return (
                <div className="maintenance-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card error-card">
                                    <div className="card-body">
                                        <div className="error-image-block">
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <img
                                                        className="img-fluid"
                                                        src={ErrorImage}
                                                        alt="img"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <h1 className="mt-4">
                                                <b>Internal Server Error</b>
                                            </h1>
                                            <p className="mt-2 mb-4 text-sm text-muted">
                                                Server error 500. we fixing the problem. please
                                                <br />
                                                try again at a later stage.
                                            </p>
                                            <p className="mt-2 mb-4 text-sm text-danger">{this.state.error?.message}</p>
                                            <a href="/" className="btn btn-primary mb-3" >
                                                Go to homepage
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

import Layouts from "../layouts"
import { Navigate } from 'react-router-dom';
import * as Pages from './Imports'
import ErrorBoundary from "../components/ErrorBoundary";


const role_routes = ( role ) => {
    const commonRoutes = [
        { path:'/patients', element: <Pages.Patients />},
        { path:'/patient/:patientId', element: <Pages.PatientProfile />},
        { path:'/appointments' , element : <Pages.Appointments/>},
        { path:'/settings', element:<Pages.Setting />},
        { path:'/apitesting', element: <Pages.APITesting />},
        { path:'/sample', element: <Pages.Sample />},
        { path:'/print-appointment/:appointmentId', element: <Pages.PrintAppointment />},
        { path:'/*', element: <Navigate to="/" />}
    ]


    switch ( role ) {

        case "RC" : return [
            { path: '/',  element: <Pages.RC_Dashboard /> },
            { path:'/payments', element:<Pages.Payments />},
            { path:'/next-visit', element:<Pages.RC_NextVisit />},
            { path:'/addmissions', element:<Pages.RC_Addmissions />},
            { path:'/rooms/:patientId', element:<Pages.SA_Rooms />},
            { path: '/rooms',  element: <Pages.SA_Rooms /> },

            ...commonRoutes,
        ]

        case 'AC' : return [
            {path : '/', element : <Pages.AC_DashBoard/> },
            {path : '/categories', element : <Pages.AC_Categories/> },
            {path : '/expenses', element : <Pages.AC_Expenses/> },
            { path:'/payments', element:<Pages.Payments />},
            {path : '/earning', element : <Pages.AC_Earning/> },
            {path : '/setup', element : <Pages.AC_Setup/> },
            ...commonRoutes,
        ]

        case "SA": return [
            { path: '/',  element: <Pages.SA_Dashboard /> },
            { path: '/users',  element: <Pages.SA_Users /> },
            { path: '/services',  element: <Pages.SA_Services /> },
            { path: '/expense',  element: <Pages.SA_Expense /> },
            { path: '/income',  element: <Pages.SA_Income /> },
            { path: '/rooms',  element: <Pages.SA_Rooms /> },
            ...commonRoutes,
        ]

        case 'DR' : return [
            { path: '/' , element : <Pages.DR_Dashboard/>},
            { path: '/tokens' , element : <Pages.DR_CurrentTokens/>},
            { path: '/payments' , element : <Pages.Payments />},
            { path:'/prescription/:appointmentId', element: <Pages.DR_Perscription />},
            { path: '/add-disease' , element : <Pages.DR_Disease/>},
            { path: '/chief-of-complaints' , element : <Pages.DR_ChiefOfComplaints/>},
            { path: '/ipd-patients' , element : <Pages.IPD_Patients/>},
            { path: '/dosing-schedules' , element : <Pages.IPD_DosingSchedules />},
            { path: '/patient-schedules/:patientId' , element : <Pages.IPD_PatientSchedule/>},

            ...commonRoutes,
        ]

        case 'JDR' : return [
            { path: '/' , element : <Pages.DR_CurrentTokens/>},
            { path:'/prescription/:appointmentId', element: <Pages.DR_Perscription />},
            { path: '/add-disease' , element : <Pages.DR_Disease/>},
            { path: '/chief-of-complaints' , element : <Pages.DR_ChiefOfComplaints/>},
            { path: '/ipd-patients' , element : <Pages.IPD_Patients/>},
            { path: '/dosing-schedules' , element : <Pages.IPD_DosingSchedules />},
            { path: '/patient-schedules/:patientId' , element : <Pages.IPD_PatientSchedule/>},

            ...commonRoutes,
        ]

        case 'PH' : return [
            { path: '/' , element : <Pages.PH_Dashboard/>},
            { path: '/medicines' , element : <Pages.PH_Medicines/>},
            { path: '/add-medicine' , element : <Pages.PH_AddMedicine/>},
            { path: '/update-medicine/:medicineId' , element : <Pages.PH_AddMedicine/>},
            { path: '/payments' , element : <Pages.Payments />},
            { path: '/invoice/:id' , element : <Pages.PH_Invoice />},
            { path:'/generate-invoice/:appointmentId', element: <Pages.PH_GenerateInvoice />},
            { path:'/add-purchase', element: <Pages.PH_AddPurchase />},
            ...commonRoutes,
        ]

        case 'IPD' : return [
            { path: '/' , element : <Pages.IPD_DashBoard/>},
            { path: '/patients' , element : <Pages.IPD_Patients/>},
            { path: '/patient-schedules/:patientId' , element : <Pages.IPD_PatientSchedule/>},
            { path: '/dosing-schedules' , element : <Pages.IPD_DosingSchedules />},
            { path: '/discharge-report/:patientId' , element : <Pages.IPD_Discharge />},
            { path: '/create-discharge-report/:patientId' , element : <Pages.IPD_CreateDischargereport />},
            { path: '/edit-discharge-report/:id' , element : <Pages.IPD_CreateDischargereport />},
            ...commonRoutes,

        ]

        case 'NR' : return [
            { path:'/' ,element: <Pages.IPD_DashBoard />},
            { path:'/patients' ,element :<Pages.IPD_Patients/> },
            { path: '/patient-schedules/:patientId' , element : <Pages.IPD_PatientSchedule/>},
            { path: '/dosing-schedules' , element : <Pages.IPD_DosingSchedules />},

            ...commonRoutes,

        ]

        case 'LB' : return [
            { path:'/' ,element: <Pages.LAB_dashboard />},
            { path:'/reports' ,element: <Pages.LAB_Reports />},
            { path:'/settings', element:<Pages.Setting />},
            ...commonRoutes,
        ]

        case 'AD' : return [
            { path:'/' , element :  <Pages.SA_Dashboard/>},
            // {path : '/reception', element:<a/>},
            { path: '/reception',  element: <Pages.RC_Dashboard /> },
            {path:"/refund" , element : <Pages.AD_Refund/>},
            { path: '/ipd-patients' , element : <Pages.IPD_Patients/>},
            { path: '/patient-schedules/:patientId' , element : <Pages.IPD_PatientSchedule/>},
            { path:'/payments', element:<Pages.Payments />},
            { path:'/appointments' , element : <Pages.Appointments/>},
            

            // ...commonRoutes,
        ]

        default:
            break;
    }
}

export default (role) => ({
    path: '/',
    element: <ErrorBoundary><Layouts /></ErrorBoundary>,
    children: role_routes(role)
})
import axios from 'axios';

axios.defaults.baseURL = `https://${ window.location.hostname }/api`;
axios.defaults.withCredentials = true;
axios.defaults.headers =  {
    'Access-Control-Allow-Origin': '*',
}

axios.interceptors.response.use(
    ( response ) =>  response,
    ( error )=> {
        if( error.response.status === 401 ){
            window.localStorage.clear()
            window.location.replace('/')
        }
    }
)

export default axios;

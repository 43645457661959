import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/custom.css'

import ShubhHMS from './ShubhHMS';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>      
      <ShubhHMS />
  </React.StrictMode>
);


// reportWebVitals(console.log);

import React from "react";

const Modal = ({open , setOpen, title = '', header, width = null, children}) => {
  return (
    <div className={`modal fade modal-animate anim-fade-in-scale ${open ? 'show d-block' : 'd-none'} `} style={{  backgroundColor:"#00000094" }}>
      <div className="modal-dialog justify-content-center">
        <div className="modal-content" style={{ width: width || "100%" }}>
          { !header && <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={()=>setOpen(false)}
            />
          </div>}
          { header }
          <div className="modal-body" style={{ width: width || "100%", maxHeight: "85vh", overflow:"auto" }}>
           {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

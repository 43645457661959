import React from "react";

const PrimaryBtn = ({title, type='submit', radius='rounded', children, ...props}) => {
  return (
    <button
      className={`w-100 btn btn-primary ${radius}`}
      type={type}
      {...props}
    >
      { children }
      {title}
    </button>
  );
};

export default PrimaryBtn;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from '../../axios';


export const login = createAsyncThunk(
    'auth/login',
    async (values) => {
        const res = await axios.post('/auth/login', values )
        return await res.data
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgot-password',
    async (values) => {
        const res = await axios.post('/auth/forgot-password', values )
        return await res.data
    }
)

const authSlice = createSlice({
    name: "auth",

    initialState: {
        user: Boolean( window.localStorage.user ) ? JSON.parse( window.localStorage.user ) : {},
        isLoggedIn: Boolean( window.localStorage.token ),
        isLoading: false,
        error: false,
        isLinkSent: false,
    },

    getInitalState: ( state ) => state,

    reducers: {
        logout:  ( state, action ) =>  {
            window.localStorage.clear()

            state.isLoggedIn = false
            state.user = {}
        },

        reset: ( state, action ) => {
            state.isLinkSent = false
            state.error = false
            state.isLoading = false
        },

        updateUser: ( state, action ) => {
            state.user = action.payload.user
            window.localStorage.setItem('user', JSON.stringify( action.payload.user ))
        }
    },

    extraReducers: (builder) => {

        /****************** Login ******************* */

        builder.addCase(login.pending, (state, action) => {
            state.isLoading = true
            state.error = false
        })

        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn
            state.isLoading = false
            state.error = false
            state.user = action.payload?.user


            const { _id, role } = action.payload?.user
            window.socket.emit('user-login', { userId: _id, role: role })

            window.localStorage.setItem('user', JSON.stringify( action.payload.user ))
            window.localStorage.setItem('token', JSON.stringify( action.payload.token ))

        })

        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.error = true
        })

        /*********************** Forgot Password **************** */

        builder.addCase(forgotPassword.pending, ( state, action ) => {
            state.isLoading = true
        })

        builder.addCase(forgotPassword.fulfilled, ( state, action ) => {
            state.isLoading = false
            state.isLinkSent = true
        })        
    }
})

export const { logout, reset, updateUser } = authSlice.actions;
export default authSlice.reducer;


const updateSocketId = () => {
    const { user } = authSlice.getInitialState();
    if( user?._id && user?.role ){
        window.socket.emit('user-login', { userId: user?._id, role: user?.role })
    }
}

updateSocketId();
import { io } from 'socket.io-client';

const socket = io(`https://${ window.location.hostname }`, { withCredentials: true })
socket.connect()

socket.on('connect', () => {
    if( localStorage.userId ){
        socket.emit('user-connected', localStorage.userId )
    }
})


socket.on('disconnect', () => console.log('Disconnected'))

window.socket = socket;
export default socket;
import PageLoader from '../../layouts/PageLoader';
import * as constant from '../../constant';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BG_LOGIN from '../../assets/images/bg-login.jpg'


export default () => {
    const { auth } = useSelector((state) => state)
    return (
        <section className="row m-0" style={{ height: "100vh", backgroundColor: "#f1f6f5" }}>
            {auth?.isLoading && <PageLoader />}
            <div className="col-lg-6 col-md-6 col-sm-12 d-none d-lg-block d-md-block px-0 h-100">
                <img src={BG_LOGIN} className='w-100 h-100' style={{ filter: 'blur(5px)' }} />
                <div className='position-absolute d-flex justify-content-center align-items-center w-50' style={{ top: "45%" }}>
                    <div id="textSlider" className="row">
                        <div className="slideCol">
                            <div className="scroller">
                                <div className="inner text-dark">
                                    <h1>Shubh Software</h1>
                                    <h1>Eraham tech</h1>
                                </div>
                            </div>
                            <p className="text-dark" style={{ fontWeight: "500" }}>Please Sign in to your account.</p>
                        </div>

                    </div>
                </div>
                <div className='position-absolute bottom-0 text-center w-100'>
                    <p>Design & Developed By <a href='https://erahamtech.com' target='_blank'>Eraham Tech Pvt. Ltd.</a></p>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center col-lg-6 col-md-6 col-sm-12" >
                <div className="auth-form w-75">
                    <div className="card  hover-effect">
                        <Outlet />
                    </div>
                </div>
            </div>
        </section>
    )
}
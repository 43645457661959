export default ({ loading = false, children, text }) => {
    return (
        <>
            {loading && 
            <span className="spinner-grow spinner-grow-sm mx-2" ></span>
            }
            { loading && text }
            { children }
        </>
    )
}
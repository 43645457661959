import React, { useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import { faAngleDown, faAnglesDown } from "@fortawesome/free-solid-svg-icons";
import OutsideWrapper from '../OutsideWrapper'

const Select = ({ list=[], label,register ={} , margin=true, ...props }) => {
  return (
    <div className={margin ? "mb-3" : ""}>
      {label && <label className="form-label">{label}</label>}
      <select className="form-select" {...register} defaultValue={list[0]} { ...props}>
        {list?.map((value, i)=>(<option className="" value={value.value} key={i}>{value.label}</option>))}
      </select>
    </div>
  );
};

export default Select;


export const MultiSelect = ({ 
  options = [], 
  optionLabel = ( data ) => data, 
  optionValue = ( data ) => data,
  onChange = () => {},
  onSelectAll = () => {},
  values = [],
  placeholder = "Multi Select",
  className = "multi-select"
}) => {

  const lists = useRef(null)
  const [isOpen, setIsOpen] = useState()

  const handleSelectAll = ( e ) => {
    const value = e.target.checked

    if(options.length){
      if( value ) onSelectAll(options.map( op => optionValue(op)))
      else onSelectAll([])

      const alloptions = Array.from( lists.current.children )
      alloptions.map(op => {
        op.children[0].checked = value
      })
    }

  }

  return (
    <div className={className}>
      <OutsideWrapper callback={() => setIsOpen(false)}>
        <div className={`d-flex justify-content-between align-items-center rounded-1 value  ${isOpen && "border-primary"}`} onClick={() => setIsOpen(!isOpen)}>
          <p className="mb-0">{values.length > 0 && (values.length + " Selected | ")}{placeholder}</p>
          <Icon icon={faAngleDown} />
        </div>

        {isOpen && <ul className="mb-0 list-unstyled dropdown shadow rounded-2 p-2 " ref={lists}>
          <li>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  id={"select-all"}
                  onChange={handleSelectAll}
                  checked={options.length == values.length}
                />
                <label htmlFor={"select-all"}>
                  {optionLabel({name: "Select All"})}
                </label>
          </li>
          {options?.length > 0 &&
            options.map((option, i) =>
              <li>
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  id={i}
                  onChange={e => onChange({ checked: e.target.checked, data: optionValue(option) })}
                  checked={values.find(c => c === optionValue(option))}
                />
                <label htmlFor={i}>
                  {optionLabel(option)}
                </label>
              </li>
            )
          }
        </ul>}
      </OutsideWrapper>
    </div>
  )
}
